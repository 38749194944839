export const firebaseConfig = {
    apiKey: "AIzaSyDzwIUhsdAnjCRgi7L5E5rqTucE58y_Y74",
    authDomain: "team-gti-management.firebaseapp.com",
    projectId: "team-gti-management",
    storageBucket: "team-gti-management.appspot.com",
    messagingSenderId: "607329795954",
    appId: "1:607329795954:web:8a7b736a978297bac467eb",
    measurementId: "G-TD9R6DDJGQ"
};

export const auth0Config = {
    client_id: 'srw99OVKwHclxb1vD7TnRda9K8HI0qvV',
    domain: 'dev-657daapf.us.auth0.com',
}


export const VERSION = "1.0.8"
// Change API code before going PRODUCTION

// export const API_URI ="http://localhost:8001"
// export const CLIENT_URL="http://localhost:3000"
export const API_URI ="https://cmapi.credentialmanagement.com.au"
export const CLIENT_URL="https://portal.credentialmanagement.com.au"