import React from 'react'
const MatxLogo = ({ className }) => {
    return (
        <img
            alt="Logo"
            src="/assets/images/logos/logo.png"
            style={{
                width: '90px',
                height: '90px',
            }}
        />
    )
}

export default MatxLogo
