export const topBarHeight = 64
export const sideNavWidth = 260

export const membershipTypeList = [
    { type: 'Full Member', short_name: 'F' },
    { type: 'Life Member', short_name: 'LM' },
    { type: 'Phar Lap Club Member', short_name: 'PNHARLAP' },
    { type: 'VRC Staff Member', short_name: 'SM' },
    { type: 'Provisional Member', short_name: 'PM' },
    { type: 'Young Member', short_name: 'YM' },
    { type: 'Junior Member', short_name: 'JM' },
    { type: 'Restricted Member', short_name: 'RM' },
]

export const seasonLists = [
    { season_name: '2023/2024', season_value: '23/24' },
    { season_name: '2024/2025', season_value: '24/25' },
    { season_name: '2025/2026', season_value: '25/26' },
]

export const GuestCardType = [
    { type: 'Guest Pass', short_name: 'G' },
    { type: 'Pre 79 Guest', short_name: '79' },
    { type: 'Restricted Guest', short_name: 'RG' },
]

export const accountStatus = [
    { type: 'Active', short_name: 'active' },
    { type: 'On Hold', short_name: 'onhold' },
    { type: 'Suspended', short_name: 'suspended' },
    { type: 'Not Financial', short_name: 'notfinancial' },
]

export const initialData = {
    companyName: '',
    front_temp: '',
    back_temp: '',
    front: {
        name: false,
        image: false,
        barcode: false,
        qrcode: false,
        yearJoined: false,
        memberId: false,
        barcodeNumber: false,
        cardNumber: false,
    },
    back: {
        name: false,
        image: false,
        barcode: false,
        qrcode: false,
        yearJoined: false,
        memberId: false,
        barcodeNumber: false,
        cardNumber: false,
    },
    front_memberId: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    front_name: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    front_yearJoined: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    front_cardNumber: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    front_barcodeNumber: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    front_image: {
        sizeX: 10,
        sizeY: 10,
        axisX1: 10,
        axisY1: 10,
        rotate: '0deg',
    },
    front_barcode: {
        sizeX: 10,
        sizeY: 10,
        axisX1: 10,
        axisY1: 10,
        rotate: '0deg',
    },
    front_qrcode: {
        sizeX: 10,
        sizeY: 10,
        axisX1: 10,
        axisY1: 10,
        rotate: '0deg',
    },
    back_memberId: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    back_name: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    back_yearJoined: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    back_cardNumber: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    back_barcodeNumber: {
        axisX: 10,
        axisX2: 10,
        axisY: 10,
        fontStyle: '',
        color: '#000000',
        fontSize: 10,
        label: '',
        align: '',
        fontDecoration: 'none',
        fontWeight: 'normal',
        italic: false,
        rotate: '0deg',
    },
    back_image: {
        sizeX: 10,
        sizeY: 10,
        axisX1: 10,
        axisY1: 10,
        rotate: '0deg',
    },
    back_barcode: {
        sizeX: 10,
        sizeY: 10,
        axisX1: 10,
        axisY1: 10,
        rotate: '0deg',
    },
    back_qrcode: {
        sizeX: 10,
        sizeY: 10,
        axisX1: 10,
        axisY1: 10,
        rotate: '0deg',
    },
    short_name: '',
}

export const FONT_DECORATION = [
    { name: 'none', value: 'none' },
    { name: 'Line Through', value: 'line-through' },
    { name: 'Underline', value: 'underline' },
]

export const FONT_WEIGHT = [
    { name: 'normal', value: 'normal' },
    { name: '100', value: '100' },
    { name: '200', value: '200' },
    { name: '300', value: '300' },
    { name: '400', value: '400' },
    { name: '500', value: '500' },
    { name: '600', value: '600' },
    { name: '700', value: '700' },
]

export const FONT_TYPE = [
    { name: 'Arial', value: 'arial' },
    { name: 'Chap Light', value: 'Chap-Light' },
    { name: 'Helvetica', value: 'Helvetica' },
    { name: 'Knockout', value: 'Knockout-HTF48-Featherweight' },
    { name: 'Akkurat Pro', value: 'AkkuratPro-Regular' },
    { name: 'Bronx Narrow', value: 'Bronx-Narrow' },
    { name: 'Bronx Bold', value: 'Bronx-Bold' },
    { name: 'Bronx Medium', value: 'Bronx-Medium' },
    { name: 'Bronx Diamond', value: 'Bronx-Diamond' },
    { name: 'Bronx Light', value: 'Bronx-Light' },
]

export const FONT_ALIGN = [
    { name: 'Left', value: 'flex-start' },
    { name: 'Center', value: 'center' },
    { name: 'Right', value: 'flex-end' },
]

export const ROTATE_OPTION = [
    { name: '0deg', value: '0deg' },
    { name: '90deg', value: '90deg' },
    { name: '180deg', value: '180deg' },
    { name: '270deg', value: '270deg' },
]

export const SHORT_NOTES =
    "Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
export const SHORT_DESCRIPTION =
    'Add more details to your event like your schedule, sponsors, or featured guests.'

export const globalStyles = { backgroundColor: '#050b3a' }

export const PASS_SEASON_YEAR = [
    { name: '2023/2024', value: '23/24' },
    { name: '2024/2025', value: '24/25' },
    { name: '2025/2026', value: '25/26' },
    { name: '2026/2027', value: '26/27' },
]

export const INITIAL_CUSTOMER_VALUE = {
    title: '',
    firstName: '',
    lastName: '',
    phone_number: '',
    email: null,
    memberId: '',
    membershipType: '',
    barcode: '',
    barcodeFormat: '',
    qr_code: '',
    phone: '',
    country: '',
    streetAddress: '',
    streetAddress2: '',
    city: '',
    state: '',
    postalCountry: '',
    account_status: '',
    postalStreetAddress: '',
    postalStreetAddress2: '',
    postalCity: '',
    cardNumber: '',
    barcodeNumber: '',
    postalState: '',
    isPostalAsHome: false,
    postalPostCode: '',
    postCode: '',
    dateJoined: '2024',
    hasGuestCard: false,
    guest_barcode: '',
    guest_type: '',
    hasGuestCard2: false,
    guest_barcode2: '',
    guest_type2: '',
    sendEmail: false,
    m_role: 'Administrator',
}

export const CRED_ROLES = [
    { name: 'Administrator', value: 'Administrator' },
    { name: 'Official', value: 'Official' },
    { name: 'Media', value: 'Media' },
    { name: 'Other', value: 'other' },
]

export const CloudDataInitial = {
    Location:
        'https://www.pngkey.com/png/full/349-3499617_person-placeholder-person-placeholder.png',
    secure_url:
        'https://www.pngkey.com/png/full/349-3499617_person-placeholder-person-placeholder.png',
}

export const PHOTO_STATUS = [
    { name: 'Waiting', value: 'waiting' },
    { name: 'Pending', value: 'pending' },
    { name: 'Current', value: 'current' },
]

export const countries = [{ name: 'Australia', code: 'AU' }]

export const STATES = [
    { name: 'New South Wales', value: 'NSW' },
    { name: 'Victoria', value: 'VIC' },
    { name: 'South Australia', value: 'SA' },
    { name: 'Tasmania', value: 'TAS' },
    { name: 'Australian Capital Territory', value: 'ACT' },
    { name: 'Western Australia', value: 'WA' },
    { name: 'Queensland', value: 'QLD' },
    { name: 'Northern Territory', value: 'NT' },
]

export const barcodeTypeList = [
    'CODE128',
    'CODE39',
    'ITF',
    'MSI',
    'pharmacode',
    'codabar',
]

export const QRBarcodeList = [
    {
        label: 'QR Code',
        ios_format: 'PKBarcodeFormatQR',
        android_format: 'QR_CODE',
    },
    {
        label: 'PDF417',
        ios_format: 'PKBarcodeFormatPDF417',
        android_format: 'PDF_417',
    },
    {
        label: 'Aztec',
        ios_format: 'PKBarcodeFormatAztec',
        android_format: 'AZTEC',
    },
    {
        label: 'Code128',
        ios_format: 'PKBarcodeFormatCode128',
        android_format: 'CODE_128',
    },
]

export const IMAGE_REJECTION_REASONS = [
    { name: 'Not centered correctly', value: 'Not centered correctly' },
    { name: 'Poor quality', value: 'Poor quality' },
    { name: 'Image not in focus', value: 'Image not in focus' },
    { name: 'Other people in background', value: 'Other people in background' },
    { name: 'Other reason - please explain', value: 'other' },
]
